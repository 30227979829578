import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../../Pages/NavBar";
import Footer from "../../Pages/Footer";
import useScrollToTop from "../../config/useScrollToTop";
import Blog from "../../assets/images/Blog.png";
import ArticleImg from "../../assets/images/blogImg.png";
import { BASE_URL, createRequestOptions } from "../../config/config";
import Loader from "../../Pages/Loader";
import noImg from "../../assets/images/no-img.jpg";

const BlogArticlePage = () => {
  useScrollToTop();
  const location = useLocation();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id, allArticle } = location.state || {}; // Check if `id` is present in the state

  const navigate = useNavigate();


  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const rawHTML = article?.description?.en || "";

  const transformHTML = (htmlString) => {
    return htmlString
      .replace(
        /<h2>/g,
        '<h2 class="text-[#2757C0] text-[2rem] font-semibold mb-6 font-helvetica mt-16">'
      )
      .replace(
        /<h3>/g,
        '<h3 class="text-2xl mt-8 font-helvetica font-semibold px-0 text-[#2757C0] mb-2">'
      ).replace(
        /<h4>/g,
        '<h4 class="text-xl font-semibold text-[#011C65] font-helvetica">'
      )
      .replace(
        /<p>/g,
        '<p class="text-[#000000] text-[20px] font-helvetica leading-relaxed mb-8">'
      )
      .replace(/<\/p>/g, "</p>");
  };
  const transformedHTML = transformHTML(rawHTML);

  useEffect(() => {
    const fetchArticle = async () => {
      if (!id) {
        setLoading(false);
        return;
      }

      try {
        console.log("Fetching article with id:", id);

        const requestBody = JSON.stringify({ id });

        // Request options
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: requestBody,
        };

        // Fetch request
        const response = await fetch(
          `${BASE_URL}/blog/get-by-id`,
          requestOptions
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        console.log("Article fetch result:", result);
        if (result.statusCode === 200) {
          setArticle(result.data);
        } else {
          console.error("Failed to fetch article:", result.message);
        }
      } catch (error) {
        console.error("Error fetching the article:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  return (
    <>
      <Navbar />
      {loading ? (
        <div className="w-full h-[600px] flex items-center justify-center">
          <Loader />
        </div>
      ) : article ? (
        <div className="bg-[#F7F9FF] font-helvetica">
          <div className="md:px-[6%] px-4 md:pt-[8%] md:pb-10 py-10">
            <div
              className={`grid grid-cols-1 md:grid-cols-[31%_50%] ipad:grid-cols-[31%_50%] items-center 
  ${
    article?.imgUrl ? "md:gap-10 ipad:gap-16" : "md:gap-[5.4%] ipad:gap-[10%]"
  }`}
            >
              <div className="flex justify-center md:justify-start md:h-auto h-[355px]">
                <img
                  src={article?.imgUrl || noImg}
                  alt="Blog"
                  className={`md:rounded-lg rounded-[20px] w-full md:w-auto  object-cover shadow-lg ${
                    article?.imgUrl
                      ? "md:max-w-[400px] h-[345px]"
                      : "md:max-w-[350px] h-[365px]"
                  }`}
                />
              </div>
              <div className="flex flex-col justify-left md:ml-6">
                <h3 className="text-[#2757C0] md:text-[2.65rem] text-4xl mt-6 font-semibold tracking-normal mb-4 font-helvetica leading-tight">
                  {article?.title?.en}
                </h3>
                <p className="text-[#192C5F] mt-4 md:text-justify text-[17.30px] font-medium font-helvetica w-auto md:max-w-[75%] leading-normal">
                  {article?.overview?.en}
                </p>
              </div>
            </div>

            <div className="md:mt-16 mt-10 md:pr-[27%] mx-auto">
              <p
                className="text-[#000000] text-[20px]  leading-tight md:text-justify  font-helvetica mb-6"
                dangerouslySetInnerHTML={{ __html: transformedHTML }}
              />
            </div>
          </div>
          <div className="bg-[#EBF0FF] md:px-[6%] py-10 px-4 pb-[4rem]">
  <h2 className="text-[#2757C0] text-[2.320rem] font-semibold font-helvetica mb-6">
    Learn More
  </h2>
  








            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  md:gap-[6rem] gap-8 ">
    {allArticle?.length > 0 ? (
      allArticle
        ?.filter((articleItem) => articleItem._id !== article?._id) // Filter out the current article
        .map((article, index) => (
          <div
          key={article._id}
          className="flex flex-col items-start cursor-pointer"
          onClick={() => {
            console.log(
              "Navigating to:",
              `/blogarticle/${article._id}`,
              { state: { id: article._id } }
            );
            navigate(`/blogarticle/${article._id}`, {
              state: {
                id: article._id,
                allArticle: allArticle,
              },
            });
          }}
         >
           <img
               src={article.imgUrl || noImg}
               alt={article.title.en}
               className="md:rounded-lg rounded-[16px] w-full md:h-auto h-[285px] md:max-h-[180px] md:max-w-[160px] object-cover mb-5 aspect-square"
             />
             <h3 className="text-[#2757C0] mb-5 leading-tight md:text-[1.700rem]  text-[2rem] md:font-semibold font-medium md:tracking-normal tracking-tight">
               {article.title.en}
             </h3>
             <p className="text-[#192C5F] text-[17.9px] text-base font-helvetica font-medium md:text-justify">
               {article.overview.en}
             </p>
         </div>
        ))
    ) : (
      <div className="text-center mt-10">
        <p className="text-[#2757C0] text-[1.5rem] font-semibold">
          No articles found.
        </p>
      </div>
    )}
  </div>
</div>

        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen text-center">
          <h1 className="text-[#2757C0] text-[2rem] font-semibold mb-4">
            No Article Found
          </h1>
          <p className="text-[#192C5F] text-[1.25rem]">
            Sorry, we couldn't find the article you're looking for.
          </p>
        </div>
      )}
      <Footer />
    </>
  );
};

export default BlogArticlePage;
