import React, { useState } from 'react';
import { Logo } from '../assets';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the mobile menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleFindDoctor = () => {
    window.open('https://vision-med.eu/sign-in', '_blank'); // Open in a new tab
  };

  const location = useLocation();
  
  // Define exact paths and patterns for dynamic routes
  const pathsWithHr = ['/', '/about-us', '/contact', '/blog'];
  const isDynamicBlogArticle = location.pathname.startsWith('/blogarticle/');

  // Check if the current path matches any of the specified paths or is a dynamic blog article page
  const shouldRenderHr = pathsWithHr.includes(location.pathname) || isDynamicBlogArticle;

  return (
    <>
 <nav className="flex items-center justify-between p-6 px-5 bg-white md:border-b border-gray-200 relative md:px-[5.7%]">
  {/* Left side: Hamburger menu button and logo */}
  <div className="flex items-center space-x-2">
    <button
      className="md:hidden text-[#00103C] hover:text-blue-600 focus:outline-none"
      onClick={toggleMenu}
    >
      {/* Toggle between hamburger and close icons based on the `isOpen` state */}
      {isOpen ? (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      ) : (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
      )}
    </button>
    <Link to="/">
      <img src={Logo} alt="Logo" className="md:h-12 h-11" />
    </Link>
  </div>

  {/* Center: Navigation links */}
  <div className="hidden md:flex items-center space-x-8 text-[1.1rem] md:px-4 ">
    <Link to="/about-us" className="text-[#00103C] hover:text-blue-600 font-medium font-inter">About Us</Link>
    <Link to="/faq" className="text-[#00103C] hover:text-blue-600 font-medium font-inter">Frequent Questions</Link>
    {/* <Link to="/blog" className="text-[#00103C] hover:text-blue-600 font-medium font-inter">Blog</Link> */}
    <Link to="/contact" className="text-[#00103C] hover:text-blue-600 font-medium font-inter">Contacts</Link>
  </div>

  {/* Right side: Find a doctor button */}
  <div className="hidden md:flex items-center">
    <button className="px-8 py-3 bg-[#05BDFB] text-white rounded-lg hover:bg-[#05BDFB] font-inter" onClick={handleFindDoctor}>
      Find a doctor
    </button>
  </div>

  {/* Mobile menu: Dropdown menu */}
  <div className={`md:hidden ${isOpen ? 'block' : 'hidden'} absolute top-[80px] left-0 w-full mt-4 bg-white z-50 py-5`}>
    <div className="flex flex-col items-center space-y-4 p-4">
      <Link to="/about-us" className="text-[#00103C] hover:text-blue-600 font-medium font-inter">About Us</Link>
      <Link to="/faq" className="text-[#00103C] hover:text-blue-600 font-medium font-inter">Frequent Questions</Link>
      {/* <Link to="/blog" className="text-[#00103C] hover:text-blue-600 font-medium font-inter">Blog</Link> */}
      <Link to="/contact" className="text-[#00103C] hover:text-blue-600 font-medium font-inter">Contacts</Link>
    </div>
  </div>

  {/* Mobile: Find a doctor button */}
  <button className="md:hidden px-3 py-2 bg-[#05BDFB] text-white rounded hover:bg-[#05BDFB] absolute right-4 font-inter" onClick={handleFindDoctor}>
    Find a doctor
  </button>
</nav>


      {shouldRenderHr ? (
        <hr
          className='md:hidden'
          style={{
            borderTop: "1px solid rgba(42, 96, 214, 1)",
            width: "100%",
            margin: "auto",
          }}
        />
      ) : null}
    </>
  );
};

export default Navbar;
