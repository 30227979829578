import React, { useState, useEffect } from "react";
import Navbar from "../../Pages/NavBar";
import Footer from "../../Pages/Footer";
import useScrollToTop from "../../config/useScrollToTop";
import { useNavigate } from "react-router-dom";
import {
  BASE_URL,
  createRequestOptions,
  createSubscribeNewsletterRequestOptions,
} from "../../config/config";
import noImg from "../../assets/images/no-img.jpg";
import Loader from "../../Pages/Loader";
import { toast, ToastContainer } from "react-toastify";

const BlogPage = () => {
  useScrollToTop();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [articles, setArticles] = useState([]);
  const [featuredArticle, setFeaturedArticle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formloading, setFormLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [allArticle, setAllArticle] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const requestOptions = createRequestOptions("POST");
        const url = `${BASE_URL}/blog/list`;

        const response = await fetch(url, requestOptions);
        const result = await response.json();
        if (result.statusCode === 200) {
          const articlesData = result.data;
          setAllArticle(articlesData);
          const featured = articlesData.find(
            (article) => article.isFeatured === 1
          );
          setArticles(articlesData.filter((article) => article !== featured));
          setFeaturedArticle(featured);
        }
      } catch (error) {
        console.error("Error fetching the data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
 
  
  

  const validateEmail = (email) => {
    const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    const formattedEmail = email.toLowerCase();
    if (!validateEmail(formattedEmail)) {
      setError("Please enter a valid email address.");
      return;
    }

    const requestBody = {
      email: formattedEmail,
    };

    try {
      setFormLoading(true);
      const response = await fetch(
        `${BASE_URL}/send-newsletter-email-to-admin`,
        createSubscribeNewsletterRequestOptions(requestBody)
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.status === "Success") {
        toast.success("Email submitted successfully!");
        setEmail("");
      } else {
        toast.error("Submission failed, please try again.");
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      toast.error("There was a problem with the submission.");
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="bg-[#F7F9FF]   md:px-[5.9%] md:py-20">
      <div className="px-4 py-10">
        <h1 className="text-[#2757C0] text-4xl md:text-[3.2rem] font-semibold mb-4">
          Helpful Articles for You
        </h1>
        <p className="text-[#0A1E54] text-lg leading-relaxed mb-10">
          Learn more about how to...
        </p>

        {loading ? (
          <div className="w-full h-[500px] flex items-center justify-center">
            <Loader />
          </div>
        ) : articles?.length > 0 ? (
          <>
            {featuredArticle && (
              <div className="grid grid-cols-1 md:grid-cols-[40%_60%] items-center  gap-8 md:mb-[6rem] mb-14">
                <div className="flex justify-center md:justify-start">
                  <img
                    src={featuredArticle.imgUrl || noImg}
                    alt={featuredArticle.title.en}
                    className="rounded-lg w-full md:max-w-[400px] cursor-pointer h-auto object-cover"
                    onClick={() =>
                      navigate(`/blogarticle/${featuredArticle._id}`, {
                        state: {
                          id: featuredArticle._id,
                          allArticle: allArticle,
                        },
                      })
                    }
                  />
                </div>
                <div className="flex flex-col justify-center cursor-pointer">
                                <h3 className="text-[#2757C0] leading-tight md:text-[2.65rem] text-[2rem] md:font-semibold font-medium md:tracking-normal tracking-tight mb-4 ">
                                  {featuredArticle.title.en}
                                </h3>
                                <p className="text-[#192C5F] md:text-justify text-[17.9px] font-normal font-helvetica w-auto md:max-w-[75%] leading-normal">
                                  {featuredArticle.overview.en}
                                </p>
                              </div>
              </div>
            )}

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  md:gap-[6rem] gap-8 ">
            {articles?.map((article, index) => (
                 <div
                 key={article._id}
                 className="flex flex-col items-start cursor-pointer"
                 onClick={() => {
                   console.log(
                     "Navigating to:",
                     `/blogarticle/${article._id}`,
                     { state: { id: article._id } }
                   );
                   navigate(`/blogarticle/${article._id}`, {
                     state: {
                       id: article._id,
                       allArticle: allArticle,
                     },
                   });
                 }}
                >
                  <img
                      src={article.imgUrl || noImg}
                      alt={article.title.en}
                      className="md:rounded-lg rounded-[16px] w-full md:h-auto h-[285px] md:max-h-[180px] md:max-w-[160px] object-cover mb-5 aspect-square"
                    />
                    <h3 className="text-[#2757C0] mb-5 leading-tight md:text-[1.700rem] text-[2rem] md:font-semibold font-medium md:tracking-normal tracking-tight">
                      {article.title.en}
                    </h3>
                    <p className="text-[#192C5F] text-[17.9px] text-base font-helvetica font-medium md:text-justify">
                      {article.overview.en}
                    </p>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="w-full h-[500px] flex items-center justify-center">
            <p className="text-[#192C5F] text-[1.5rem]">No blogs found</p>
          </div>
        )}
        </div>

        {/* Conditional rendering of image at the end for mobile view */}
        {isMobile && (
          <div className="w-full bg-[#F7F9FF] mt-6">
            <div className="w-full bg-[#F2F4FF] py-10  flex flex-col items-left">
              <h2 className="text-[#2757C0] px-4 text-4xl font-semibold text-left tracking-tight leading-tight mb-6">
                Let’s keep in touch
              </h2>
              <p className="text-[#192C5F] px-4 mb-6 text-left font-semibold text-[17.50px]">
                Sign up to become a part of the community
              </p>
              <form
                className="w-full flex items-center px-4 gap-4"
                onSubmit={handleSubmit}
              >
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="jane@janesdomain.net"
                  className="flex-1 px-3 py-3 border rounded-lg w-[240px] text-[#192C5F] text-lg"
                />
                <button
                  type="submit"
                  className="py-3 px-5 bg-[#2757C0] text-white text-lg font-medium rounded-lg"
                >
                  {formloading ? <Loader /> : "Submit"}
                </button>
              </form>
              {error && <p className="text-red-500 mt-2">{error}</p>}
            </div>
          </div>
        )}
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default BlogPage;
