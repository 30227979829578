import React, { useState, useEffect } from "react";
import DoctorImg from "../../assets/images/doctorimage.png";
import ArrowImg from "../../assets/images/Arrow.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DoctorSection from "./DoctorSection";
import ServiceCards from "./ServiceCard";
import MobileArrow from "../../assets/images/MobileArrow.png";
import { toast, ToastContainer } from "react-toastify";
import { GoPlus } from "react-icons/go";
import { RxCross2 } from "react-icons/rx";
import plusIcon from "../../assets/images/Union.png";
import "react-toastify/dist/ReactToastify.css";
import crossImg from "../../assets/images/cross.png";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import tailRight from "../../assets/images/tail-right.png";
import {
  BASE_URL,
  createSubscribeNewsletterRequestOptions,
  createRequestOptions,
} from "../../config/config";
import Loader from "../../Pages/Loader";
import {
  steps,
  features,
  sections,
  expertise,
  doctors,
  partners,
  pageBasicContent,
  sectionsContent,
} from "./HomeData";
import Navbar from "../../Pages/NavBar";
import Footer from "../../Pages/Footer";
import useScrollToTop from "../../config/useScrollToTop";
import { animateScroll as scroll, scroller } from "react-scroll";
import Fill from "../../assets/images/Fill.png";
import Oval from "../../assets/images/Oval.png";
import { CrossIcon } from "lucide-react";
const Home = () => {
  useScrollToTop();
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [cardLoading, setCardLoader] = useState(false);

  const [questions, setQuestions] = useState([
    {
      id: 1,
      question:
        "My medical reports are in a language other than English or German. Can I still book a second opinion?",
      answer:
        "Yes, VISION MED offers an intuitive platform that allows you to upload documents in any language. You can either provide your own translated version or utilize VISION MED's translation service to ensure your records are accessible to the German medical experts.",
      isOpen: true,
    },
    {
      id: 2,
      question:
        "Can I upload all the information I have, including lab results and doctor's notes, for the second opinion?",
      answer:
        "Yes, you are encouraged to upload all relevant medical documents and information. This can include lab results, imaging reports, doctor's notes, and any other records that may help the German medical expert conducting your second opinion review.",
      isOpen: false,
    },
    {
      id: 3,
      question: "Can I choose the doctor I want for my second opinion?",
      answer:
        "Yes, VISION MED's platform allows you to browse a list of specialized doctors and select your preferred cancer specialist. You can choose a doctor based on their expertise and your specific needs.",
      isOpen: false,
    },
    {
      id: 4,
      question:
        "What if I need to cancel or reschedule my video consultation due to a last-minute conflict?",
      answer:
        "You can cancel or reschedule your video consultation in advance through the VISION MED platform. It is important to provide adequate notice to ensure efficient use of the doctor's time and yours.",
      isOpen: false,
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setCardLoader(true);

        // Use createRequestOptions to build the request options
        const requestOptions = createRequestOptions("GET");
        const url = `${BASE_URL}/service/home-page-list`;

        const response = await fetch(url, requestOptions);

        const result = await response.json();

        if (result.statusCode === 200) {
          setServices(result.data);
        }
      } catch (error) {
        console.error("Error fetching the data", error);
      } finally {
        setCardLoader(false);
      }
    };
    fetchData();
  }, []);

  // Email validation function
  const validateEmail = (email) => {
    const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    // Convert email to lowercase and validate
    const formattedEmail = email.toLowerCase();
    if (!validateEmail(formattedEmail)) {
      setError("Please enter a valid email address.");
      return;
    }

    // Prepare the request body
    const requestBody = {
      email: formattedEmail,
    };

    try {
      setLoading(true);
      const response = await fetch(
        `${BASE_URL}/send-newsletter-email-to-admin`,
        createSubscribeNewsletterRequestOptions(requestBody)
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.status === "Success") {
        setLoading(false);
        toast.success("Email submitted successfully!");
        setEmail(""); // Clear the input field on success
      } else {
        toast.error("Submission failed, please try again.");
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      toast.error("There was a problem with the submission.");
    } finally {
      setLoading(false);
    }
  };

  const toggleQuestion = (id) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question) =>
        question.id === id
          ? { ...question, isOpen: !question.isOpen }
          : question
      )
    );
  };

  const handleSignIn = () => {
    window.open("https://vision-med.eu/sign-in", "_blank"); // Open in a new tab
  };

  const handleRegistration = () => {
    window.open("https://vision-med.eu/sign-up", "_blank");
  };

  const handleLinkClick = (event, linkText) => {
    console.log("linkText", linkText);

    event.preventDefault(); // Prevent default anchor behavior

    if (linkText === "Try the platform" || linkText === "Register") {
      handleRegistration();
    } else if (linkText === "Find your doctor") {
      handleSignIn();
    }
  };
  // const applyResponsiveStyles = () => {
  //   console.log("window.innerWidth:", window.innerWidth);
  
  //   const mainblock = document.querySelector('.mainblock');
  //   const iframe = document.querySelector('.frame');
  //   const btmElement = document.querySelector('.btmElement');
  //   const topElement = document.querySelector('.topElement');
  //   const insideContainer = document.querySelector('.inside-container');
    
    
  
  //   // Check for iPad Mini screen size (744px to 834px) - specific condition first
  //   if (window.innerWidth <= 767) {
  //     if (mainblock && iframe && btmElement && topElement) {
  //       mainblock.style.width = '95%'; // Mobile-specific styling
  //       mainblock.style.margin = '0 auto';
  //       mainblock.style.display = 'block'; // Adjust as needed
       
  //       btmElement.style.bottom = '-20px'; // Adjust based on design
  //       btmElement.style.left = '-10px';
  //       topElement.style.top = '-10px';
  //       topElement.style.right = '20px';
  //       insideContainer.style.aspectRatio = 0;
      
  //     }
  //   }
  //   else if (window.innerWidth == 1024 ) {
  //     if (mainblock && iframe && btmElement && topElement) {
  //       mainblock.style.width = '90%';
       
  //       iframe.style.width = "110%";
  //       btmElement.style.bottom = '-57px';
  //       btmElement.style.left = '-55px';
  //       topElement.style.top = '-17px';
  //       topElement.style.right = '-11px';
  //       insideContainer.style.aspectRatio = 16/16;
  //     }
  //   }
    
  //  else if (window.innerWidth == 1026 ) {
  //     if (mainblock && iframe && btmElement && topElement) {
  //       mainblock.style.width = '90%';
     
  //       btmElement.style.bottom = '-62px';
  //       btmElement.style.left = '-60px';
  //       topElement.style.top = '-19px';
  //       topElement.style.right = '6px';
  //       insideContainer.style.aspectRatio = 16/16;
  //     }
  //   }
    
  //   // Check for iPad screen size (820px to 1280px)
  //   else if (window.innerWidth >= 820 && window.innerWidth <= 1280) {
  //     if (mainblock && iframe && btmElement && topElement) {
  //       mainblock.style.width = '85%';
      
  //       btmElement.style.bottom = '-47px';
  //       btmElement.style.left = '-62px';
  //       topElement.style.top = '-19px';
  //       topElement.style.right = '51px';
  //       insideContainer.style.aspectRatio = 16/16;
  //     }
  //   }
  //   // Check for Desktop screen size (1281px and above)
  //   else if (window.innerWidth == 1281 || window.innerWidth == 1536) {
  //     if (mainblock && iframe && btmElement && topElement) {
  //       mainblock.style.width = '90%';
      
  //       btmElement.style.bottom = '-58px';
  //       btmElement.style.left = '-30px';
  //       topElement.style.top = '-18px';
  //       topElement.style.right = '81px';
  //       insideContainer.style.aspectRatio = 16/16;
  //     }
  //   }
  //   else if (window.innerWidth >= 1600 && window.innerWidth <=2500) {
  //     if (mainblock && iframe && btmElement && topElement) {
  //       mainblock.style.width = '90%';
       
  //       btmElement.style.bottom = '-98px';
  //       btmElement.style.left = '-81px';
  //       topElement.style.top = '-19px';
  //       topElement.style.right = '158px';
  //       insideContainer.style.aspectRatio = 16/16;
  //     }
  //   }
  // };
  
  // // UseEffect to apply styles on component mount and window resize
  // useEffect(() => {
  //   // Initial style application
  //   applyResponsiveStyles();
  
  //   // Add event listeners for window resize
  //   window.addEventListener('resize', applyResponsiveStyles);
  
  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('resize', applyResponsiveStyles);
  //   };
  // }, []);

  // Handle scrolling when the component mounts
  useEffect(() => {
    const hash = location.hash.replace("#", "");
    if (hash) {
      scroller.scrollTo(hash, {
        smooth: true,
        duration: 500,
      });
    }
  }, [location]);

  const handleNavigation = (path, section) => {
    navigate(`${path}#${section}`);
  };

  return (
    <>
      <Navbar />
      <GoogleReCaptchaProvider
        reCaptchaKey="6LcUkkkpAAAAAJLkP5GIoRJkphidbyrj-gyQ7TcW"
        useRecaptchaNet={true}
        scriptProps={{
          async: false,
          defer: false,
          appendTo: "head",
          nonce: undefined,
        }}
      >
        <div className="min-h-screen  bg-[#FAFBFF] flex flex-col   md:pt-8 pt-6 overflow-hidden ">
          {/* Header Section */}
          <div
            className="max-w-[100%] w-full mx-auto px-5 md:px-[6%] md:py-0 md:pr-[27px] desktop-grid"
            style={{ position: "relative" }}
          >
            {/* Left Side: Text Content */}
            <div
              className="text-container"
              style={{
                zIndex: 20,
              }}
            >
              <h1
                className="leading-tight tracking-tight text-4xl font-semibold text-[#2757C0] md:text-[3.2rem] md:mb-8 py-5 md:py10 md:pl-[-0.8] font-helvetica"
                style={{ position: "relative", zIndex: 20 }}
              >
                {pageBasicContent?.title}
              </h1>

              <p
                className="text-justify text-[#00103C] text-[18px] font-normal mb-8 font-opensans w-[85%]"
                style={{ position: "relative", zIndex: 20 }}
                dangerouslySetInnerHTML={{ __html: pageBasicContent?.info }}
              ></p>

              <button
                type="button"
                onClick={handleSignIn}
                className="bg-[#05BDFB] font-inter font-bold text-[20px] text-white md:px-15 px-10 py-4 rounded-lg hover:bg-[#05BDFB] focus:outline-none w-auto md:w-auto text-center"
                style={{ position: "relative", zIndex: 20 }}
              >
                {pageBasicContent?.btnText}
              </button>
            </div>

            {/* Right Side: Video and Graphic Elements */}
            <div className=" relative w-full md:w-[90%] mt-6 md:mt-2">
            <div className="hidden md:block absolute top-[-27px] right-[-7px] z-0">
                <img
                  src={Fill}
                  alt="Top Graphic Element"
                  className="w-[100px] h-[99px]"
                />
              </div>
                <div className="hidden md:block absolute bottom-[-58px] left-[-30px] z-0">
                  <img
                    src={Oval}
                    alt="Bottom Graphic Element"
                    className="w-[150px] h-[140px]"
                  />
                </div>

                {/* Video container */}
                <div
                  className="w-full md:w-[94%] aspect-video relative inside-container md:mb-0 mb-8"
                  style={{ zIndex: 10 }}
                >
                  <iframe
                    className="w-full h-full rounded-lg"
                    src="https://www.youtube.com/embed/fOHSCiNT8rk?rel=0"
                    title="Doctor"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ position: "relative", zIndex: 10 }}
                  ></iframe>
                </div>
              </div>
            </div>
          
          {/* Info Section */}

          <div className="bg-[#EBF0FF] md:mt-[3%]">
            <div className="px-5 md:pl-[9%] md:pr-[15%] md:py-20 py-5 mx-auto grid grid-cols-1 md:grid-cols-2 gap-x-[8rem] gap-y-10 mt-6">
              {" "}
              {/* Adjusted gap-x and gap-y */}
              {expertise.map((feature, index) => (
                <div key={index} className="flex items-start mb-6">
                  <div className="flex-shrink-0">
                    <img
                      src={feature.icon}
                      className="w-[70px] h-[67px] mt-4"
                      alt={feature.title}
                    />
                  </div>
                  <div className="ml-4 mt-3">
                    <h3 className="  text-[1.900rem] font-semibold text-[#2757C0] font-opensans leading-tight tracking-normal">
                      {feature.title}
                    </h3>
                    <ul className="list-none   text-[#00103C]  space-y-2  mt-4 font-inter">
                      {feature.description.map((desc, i) => (
                        <li
                          key={i}
                          className="text-[18px] font-normal"
                          dangerouslySetInnerHTML={{ __html: desc }}
                        ></li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* How It Works Section */}
          <div id="how-it-works" className="w-full section md:px-0">
            <div
              className=" w-full mx-auto  md:pl-[6%] md:pr-[4.8%] px-6 md:py-10 py-4"
              style={{
                background: "linear-gradient(to bottom, #EBF0FF, #FFFFFF)",
              }}
            >
              <h1 className="leading-tight text-4xl font-semibold text-[#2757C0] md:text-[3.2rem] md:mb-8 py-5 md:py-0 font-helvetica  md:!mr-[-20%]">
                {sectionsContent?.title}
              </h1>
              {/* <h1 className=" leading-tight text-[3.2rem] font-semibold text-[#2757C0] md:text-[3.2rem] ">
                {sectionsContent?.title}
              </h1> */}

              <div className="w-full   flex justify-center">
                <div className="mt-10 border w-full purple-box border-[#715FFF] rounded-3xl py-6  px-4 mb-16 shadow-md bg-[#F6F5FF]">
                  <div className="flex flex-col lg:flex-row lg:justify-between">
                    <p className="md:text-[18px] text-[23px]  text-[#0A1E54] mb-2 lg:mb-0 md:px-4 lg:mr-2 mt-4 font-opensans font-semibold">
                      {sectionsContent?.subHead}
                    </p>
                    <p className="md:text-justify text-[18px]  font-normal text-[#0A1E54] lg:text-left lg:ml-auto mt-2 lg:mt-0 lg:w-[52%]  font-opensans">
                      {sectionsContent?.info}
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="w-full flex flex-col md:flex-row justify-start items-start mb-5 md:mb-0 mt-[2px]"
                style={{ marginLeft: "-8", marginRight: "2%" }}
              >
                {steps.map((step, index) => (
                  <React.Fragment key={step.id}>
                    <div
                      className="flex flex-col w-full md:w-[17%] items-center"
                      style={{
                        textAlign: "center",
                        marginLeft: index === 0 ? "0" : "2%",
                        marginRight: "2%",
                        paddingLeft: "0",
                      }}
                    >
                      <div className="flex flex-col h-full justify-between items-center">
                        <div
                          className="w-[8rem] h-[8rem] md:w-16 md:h-16 bg-gray-200 rounded-full mb-2 flex items-center justify-center"
                          style={{
                            width: "8rem",
                            height: "8rem",
                            backgroundColor: "#f0f0f0",
                            borderRadius: "50%",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <img
                            src={step?.img}
                            alt="Step"
                            className="w-full h-full rounded-full object-cover"
                            style={{
                              borderRadius: "50%",
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <p
                          className="sub-head md:text-[#0A1E54] text-[#002998] w-[60%]  font-semibold font-opensans break-words md:w-full"
                          style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            lineHeight: "1.2em",
                            wordBreak: "break-word",
                            marginTop: "0.5rem",
                          }}
                        >
                          {step.title}
                        </p>
                        <button
                          className="bg-transparent font-opensans mb-4 md:mb-0 text-[#0E8A0C] border-2 border-[#0E8A0C] rounded-full px-3 py-1 font-semibold h-10 flex items-center justify-center whitespace-nowrap mt-3"
                          style={{
                            backgroundColor: "transparent",
                            color: "#0E8A0C",
                            border: "2px solid #0E8A0C",
                            borderRadius: "9999px",
                            padding: "0.5rem 1rem",
                            fontSize: "14px",
                            fontWeight: "600",
                            marginTop: "0.5rem",
                          }}
                        >
                          {step.time}
                        </button>
                      </div>
                    </div>
                    {index < steps.length - 1 && (
                      <>
                        <div
                          className="hidden md:flex justify-center items-center w-full md:w-[10%]"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "6.9rem",
                          }}
                        >
                          <img
                            src={ArrowImg}
                            alt="Arrow"
                            style={{
                              width: "40px",
                              height: "auto",
                              marginLeft: "0",
                              marginRight: "0",
                            }}
                          />
                        </div>
                        <div
                          className="flex md:hidden justify-center items-center w-full"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "1rem",
                            marginBottom: "19px",
                          }}
                        >
                          <img
                            src={MobileArrow}
                            alt="Arrow"
                            style={{
                              width: "20px",
                              height: "auto",
                              marginLeft: "0",
                              marginRight: "0",
                            }}
                          />
                        </div>
                      </>
                    )}
                  </React.Fragment>
                ))}
              </div>

              <p className="text-[#0A1E54] font-semobold text-[1.049rem]  md:text-xl mt-[7%] text-center font-inter">
                {sectionsContent?.subInfo}
              </p>
              <div className="w-full flex justify-center">
                <button
                  type="button"
                  onClick={handleRegistration}
                  className="bg-[#05BDFB] font-inter text-justify font-bold text-[20px] text-white px-14 py-4 rounded-lg hover:bg-[#05BDFB] focus:outline-none  mt-8"
                >
                  {sectionsContent?.btnText}
                </button>
              </div>
            </div>

            <div className="min-h-screen w-full bg-[#FFFFFF] flex flex-col items-center">
              {sections.map((section, index) => (
                <div
                  key={section.id}
                  className="mt-12 w-full flex flex-col md:flex-row items-center justify-center md:justify-between px-4 md:pl-[15%] md:pr-[10%] md:py-8"
                >
                  {/* Image Section */}
                  <div
                    className={`flex-1 ${
                      index % 2 === 0
                        ? "md:order-1 md:mr-10"
                        : "md:order-2 md:ml-10"
                    } mb-6 md:mb-0 flex justify-center md:justify-start`}
                  >
                    <img
                      src={section.image}
                      alt={section.title}
                      className="w-full max-w-full  h-auto rounded-lg imgstyling"
                    />
                  </div>

                  {/* Text Section */}
                  <div
                    className={`flex-1 ${
                      index % 2 === 0
                        ? "md:order-2 md:ml-0"
                        : "md:order-1 md:mr-1"
                    } text-left flex justify-center md:justify-start`}
                  >
                    <div
                      className={`flex-1 ${
                        index % 2 === 0 ? "textOne " : "texttwo "
                      } `}
                    >
                      <h2 className="text-[1.70rem]  subheading md:w-[89%] font-opensans font-normal text-[#0A1E54] md:text-3xl mb-4">
                        {section.title}
                      </h2>
                      <p className="text-justify text-[#000000] subheading text-[1.150rem] md:w-[89%] font-normal mb-4 font-opensans">
                        {section.description}
                      </p>
                      <a
                        href={section.linkUrl}
                        onClick={(e) => handleLinkClick(e, section.linkText)}
                        className="inline-flex items-center text-justify text-[#2757C0] text-[1.150rem] font-semibold hover:underline font-opensans"
                      >
                        {section.linkText}{" "}
                        <img
                          src={tailRight}
                          alt="arrow-icon"
                          className="ml-2 w-4 h-4"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
              <div className="w-full flex justify-center">
                <button
                  type="button"
                  onClick={handleSignIn}
                  className="bg-[#05BDFB] font-inter font-bold text-[20px] text-white px-14 py-4 rounded-lg hover:bg-[#05BDFB] focus:outline-none mt-8 mb-10"
                >
                  {sectionsContent?.btnText2}
                </button>
              </div>
            </div>

            <div
              style={{
                background: "linear-gradient(180deg, #EBF0FF 0%, #FFFFFF 100%)",
              }}
            >
              <div
                id="why-choose-us"
                className="px-4 mx-auto md:px-[6%] lg:px-[6%] py-8"
              >
                <h2 className=" text-4xl  md:text-[3.2rem] font-semibold text-[#2757C0]  md:mb-12 md:py-10 py-5 mb-5 font-opensans">
                  {sectionsContent?.whyChooseUs}
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[15rem] gap-y-10 mt-2 px-2">
                  {features.map((feature, index) => (
                    <div key={index} className="flex items-start">
                      <div className="flex-shrink-0">
                        <img
                          src={feature.icon}
                          className="w-7 h-auto"
                          alt={feature.title}
                        />
                      </div>
                      <div className="ml-4">
                        <h3 className="text-justify  text-xl font-semibold text-[#011C65] font-helvetica">
                          {feature.title}
                        </h3>
                        <p className="mt-2 text-justify  text-[#0A1E54] font-inter">
                          {feature.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div id="our-doctors" className="w-full py-12 bg-white">
              <div className="px-5 md:px-[6%] w-full mx-auto ">
                <h2 className=" text-4xl  md:text-[3.2rem] font-semibold text-[#2757C0]  mb-12 font-opensans text-left">
                  {sectionsContent?.doctorSection}
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-[3%] doctorsection  mt-20 text-left">
                  {doctors.map((doctor, index) => (
                    <DoctorSection key={index} doctor={doctor} />
                  ))}
                </div>
                <div className="flex justify-center md:mt-10 mt-10">
                  <button
                    type="button"
                    onClick={handleSignIn}
                    className="bg-[#05BDFB] text-white px-[20%] md:px-14 py-4 rounded-lg font-bold text-[20px] hover:bg-[#05BDFB] focus:outline-none  mt-8 font-opensans text-center"
                  >
                    {sectionsContent?.whyChooseUsBtn}
                  </button>
                </div>
              </div>
            </div>

            <div
              id="our-partners"
              className="px-5 md:px-[6%] w-full mx-auto bg-white md:py-10 py-5"
            >
              <h2 className="text-4xl  md:text-[3.2rem] font-semibold text-[#2757C0] md:mb-3 mb-4 font-opensans">
                {sectionsContent.partnerSection}
              </h2>

              <div className="flex flex-wrap md:flex-nowrap justify-center items-start md:py-16 py-10 gap-10 mb-10 md:mt-2 mt-[2%] font-opensans">
                {partners.map((partner, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-start w-full max-w:[30rem]  mt-[3%] mb-3"
                  >
                    <img
                      className="md:h-18 h-16 max-w-full object-contain mb-2"
                      src={partner.logo}
                      alt={partner.name}
                    />
                    <h3 className="md:text-[20px] md:h-[3.5rem] text-2xl md:w-[85%] font-semibold text-[#0A1E54] mt-4 font-opensans md:leading-[22px]">
                      {partner.name}
                    </h3>
                    <p
                      className="md:text-justify text-xl md:text-sm text-[#0A1E54] mt-4 font-opensans md:w-[85%]"
                      dangerouslySetInnerHTML={{ __html: partner.description }}
                    ></p>
                  </div>
                ))}
              </div>
            </div>

            <div
              className=" px-5 md:py-10 py-5 md:px-[6%] w-full mx-auto "
              style={{
                background: "linear-gradient(180deg, #EBF0FF 0%, #FFFFFF 100%)",
              }}
            >
              {cardLoading ? (
                <div className="w-full h-[500px] flex items-center justify-center">
                  <Loader />
                </div>
              ) : (
                <>
                  {" "}
                  <h2 className="text-4xl  md:text-[3.2rem] font-semibold  text-[#2757C0] md:mb-8 mb-4 mt-6 text-left font-opensans leading-18">
                    {sectionsContent.packages}
                  </h2>
                  <ServiceCards services={services} />
                </>
              )}
              <div className="max-w-full w-full mx-auto text-left mb-[5%] mt-[10%] ">
                <h2 className="text-justify  text-4xl md:text-[3.2rem] font-semibold text-[#2757C0] mb-12 sm:mb-10 md:mb-[5%] font-opensans md:px-0 px-2">
                  {sectionsContent?.faq}
                </h2>
                {questions.map((question, index) => (
                  <div
                    key={question.id}
                    className=" w-full sm:w-[90%] md:w-[80%] px-2 md:px-0"
                  >
                    <div
                      className={`p-4  sm:p-8 md:p-8 shadow-md cursor-pointer ${
                        question.isOpen ? "bg-[#E5EDFF]" : "bg-[#F5F8FF]"
                      } ${
                        index === 0
                          ? "md:rounded-t-[2rem] rounded-t-[1.4rem]"
                          : ""
                      } ${
                        index === 3
                          ? "md:rounded-b-[2rem] rounded-b-[1.4rem]"
                          : ""
                      }`}
                      onClick={() => toggleQuestion(question.id)}
                    >
                      <div className="flex items-start justify-between">
                        <div className="md:mt-0 mt-3 flex items-start w-full">
                          <span className="text-3xl sm:text-xl md:text-3xl font-semibold text-[#001D6D] mr-5 md:mr-0 py:0 font-opensans flex-shrink-0">
                            {question.id < 10 ? `0${question.id}` : question.id}
                          </span>
                          <span className="flex-grow text-lg sm:text-xl md:text-[1.350rem] md:leading-8 leading-6 font-semibold text-[#001D6D] font-opensans md:text-justify  md:pl-10 lg:pl-18">
                            {question.question}
                          </span>
                        </div>
                        <div
                          className={`flex items-center justify-center w-6 ml-6 h-6 sm:w-8 sm:h-8 md:mt-0 mt-2 md:p-2 ${
                            question.isOpen
                              ? "bg-[#C9D9FF] text-[#001D6D]"
                              : "bg-[#FFFFFF] text-[#000000]"
                          } rounded-full flex-shrink-0`}
                        >
                          <span>
                            {question.isOpen ? (
                              <img
                                src={crossImg}
                                style={{ width: "11px", height: "11px" }}
                              />
                            ) : (
                              <img
                                src={plusIcon}
                                style={{ width: "11px", height: "11px" }}
                              />
                            )}
                          </span>
                        </div>
                      </div>
                      {question.isOpen && question.answer && (
                        <div className="mt-2 pl-[3.500rem] md:pl-14 lg:pl-[4.500rem]">
                          <p className="text-[#001D6D] font-medium text-sm sm:text-base md:text-[1.10rem] font-inter md:text-justify pr-7">
                            {question.answer}
                          </p>
                        </div>
                      )}
                    </div>
                    {index < 3 && (
                      <hr
                        style={{
                          borderTop: "0.2px solid #C1D4FF",
                          width: "100%",
                          margin: "auto",
                        }}
                      />
                    )}
                  </div>
                ))}
                <div className="mt-8 sm:mt-10 md:mt-10 px-2">
                  <Link
                    to="/faq"
                    className="inline-flex items-center text-[#2757C0]  text-[1.500rem] md:text-[1.440rem] font-semibold no-underline font-opensans"
                  >
                    {sectionsContent?.link}
                    <img
                      src={tailRight}
                      alt="arrow-icon"
                      className="ml-2 w-4 h-4"
                    />
                  </Link>
                </div>
                <div className="md:flex hidden   justify-center mt-8 sm:mt-10 md:mt-14">
                  <button
                    type="button"
                    onClick={handleRegistration}
                    className="bg-[#05BDFB] font-bold text-[20px] text-white px-10 sm:px-12 md:px-14 py-3 rounded-lg font-inter hover:bg-[#05BDFB] focus:outline-none"
                  >
                    {sectionsContent?.regBtn}
                  </button>
                </div>
              </div>
            </div>

            <div className="w-full py-16 bg-[#EBF0FF] px-4">
              <div className="px-2 md:px-[13%] w-full mx-auto md:text-center text-left font-opensans">
                <h2 className="text-4xl  md:text-[3.2rem] font-semibold text-[#2757C0] mb-16">
                  {sectionsContent?.formHeading}
                </h2>
                <p className="mb-4 text-[#0A1E54] font-semibold font-opensans md:text-center">
                  {sectionsContent.formLink}
                </p>
                <form
                  onSubmit={handleSubmit}
                  className="flex flex-col items-center w-full" // added w-full and removed md:items-center for consistency
                >
                  <div className="flex w-full md:w-[42%] space-x-2 mx-auto">
                    {" "}
                    {/* Changed ml-0 to mx-auto for horizontal centering */}
                    <input
                      type="email"
                      placeholder="jane@janesdomain.net"
                      className={`px-[0.700rem] py-[0.8rem] border border-[#E0E0E0] rounded-lg font-inter focus:ring-1 focus:ring-[#2A60D6] w-full focus:outline-none ${
                        error ? "border-red-500" : "border-[#2A60D6]"
                      }`}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <button
                      type="submit"
                      className={`mr-2 bg-[#2A60D6] text-white rounded-lg font-inter focus:outline-none focus:ring-2 focus:ring-[#2A60D6] ${
                        loading
                          ? "opacity-50 cursor-not-allowed px-10 py-[0.8rem]"
                          : "px-6 py-[0.8rem]"
                      }`}
                      disabled={loading}
                    >
                      {loading ? <Loader /> : sectionsContent.formBtn}
                    </button>
                  </div>
                  {error && (
                    <p className="text-red-500 mt-1 text-left w-full md:w-[40%]">
                      {error}
                    </p>
                  )}
                </form>
              </div>
            </div>
            <style jsx>{`


              .desktop-grid {
                display: block; /* Default layout for mobile and smaller screens */
              }

           
    


              @media (min-width: 1024px) {
                /* Apply grid only on desktop view */
                .mainblock{
                width:90%
                }
                .desktop-grid {
                  display: grid;
                  grid-template-columns: 1fr 1fr; /* Two equal columns */
                  gap: 20px; /* Space between the two columns */
                  align-items: start; /* Align content at the top */
                }
                .purple-box {
                  width: 70%;
                }

                .text-container,
                .video-container {
                  margin: 0; /* Remove extra margin on desktop */
                }
              }
              .text-container {
                position: relative;
                z-index: 20;
              }

              .video-container {
                position: relative;
                z-index: 10;
                margin-top: 20px;
              }

              @media (min-width: 2075px) and (max-width: 2270px),
                (min-width: 1685px) and (max-width: 1810px) {
                .text-container {
                  padding-top: 20px;
                }

                .video-container {
                  margin-top: 10px;
                }
              }

              @media (min-width: 820px) and (max-width: 1180px),
                (min-width: 1685px) and (max-width: 1810px) {
                .purple-box {
                  width: 90%;
                }
              }

              /* Specific adjustments for Edge browser */
              @supports (-ms-ime-align: auto) {
                /* Edge-specific fix */
                .video-container iframe {
                  width: 100%;
                  height: auto;
                  object-fit: contain;
                }
              }
              @media (min-width: 768px) {
                .sub-head {
                  width: 100%;
                  min-height: 4.8em;
                }
              }

              /* Adjust image styling for iPad dimensions */
              @media only screen and (min-width: 768px) and (max-width: 1024px) {
                .subheading {
                  width: 98%;
                }
                .imgstyling {
                  max-width: 100%;
                  height: auto; /* Maintain aspect ratio */
                  object-fit: cover; /* Ensure the image scales correctly without distortion */
                }
                .purple-box {
                  width: 92%;
                }
                .textOne {
                  max-width: 100%;
                }
                .texttwo {
                  max-width: 100%;
                }
              }

              /* Adjust image styling for larger screens */
              @media only screen and (min-width: 1025px) {
                .imgstyling {
                  max-width: 80%;
                  height: auto; /* Maintain aspect ratio */
                  object-fit: cover; /* Ensure the image scales correctly without distortion */
                }
                .textOne {
                  max-width: 89%;
                }
                .texttwo {
                  max-width: 90%;
                }
              }
            `}</style>
          </div>
          <ToastContainer />
        </div>
        <Footer />
      </GoogleReCaptchaProvider>
    </>
  );
};

export default Home;
